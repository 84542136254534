<template>
  <ion-page>
    <div id="dashboard-sub">
      <base-layout>
        <ion-content class="has-header has-bottom-banner" style="" :scroll-x="false" :scroll-y="false">
          <h2 class="ion-padding" v-html="parentLink.title['en']" v-if="parentLink.title"></h2>
          <div class="dashboard-content"
               style="background:white; overflow:scroll;padding-bottom:70px; height:100%; margin-top:0px;">
            <div class="menu-wrapper">
              <template v-for="link in menulinks" :key="link.id">
                <a
                    @click="openLink(link)"
                    class="tile-item" v-if="!isExternalLink(link)"
                    style="position:relative; ">
                  <i class="dashboard-item-icon" :class="link.icon_style"></i>

                  <span class="text" v-html="link.title['en']"
                        style="color: #24802e; display:block;white-space:normal;"></span>
                </a>

                <a
                    :href="link.target" target="_blank"
                    class="tile-item" v-if="isExternalLink(link)"
                    style="position:relative; background: white !important">
                  <i class="dashboard-item-icon" :class="link.icon_style"></i>

                  <span class="text" v-html="link.title['en']"
                        style="color: #24802e;display:block;white-space:normal;padding-right:10px"></span>
                </a>
              </template>
            </div>
          </div>

        </ion-content>
      </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  alertController,
  toastController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {addIcons} from "ionicons";
import {syncOutline} from "ionicons/icons";
import router from "@/router";

addIcons({"sync-outline": syncOutline});
const config = require('@/config')

export default {
  name: "DashboardSub",
  components: {},
  data() {
    return {
      parentLink: {},
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getChildren', 'getLinkById']),
    ...mapActions('sync', ['start']),
    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',
          })
      return toast.present();
    },
    login() {

    },
    goToProfile() {

    },
    isExternalLink(link) {
      return new RegExp("^(http|https)://").test(link.target)
    },
    async openLink(link) {
      console.log(link)
      let _link = this.determineTarget(link)

      if(!this.user){
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Keine Berechtigung',
              message: '<strong>Keine Berechtigung.<br>Der Zugriff zu dieser Website ist ab 4.10.2022 möglich.</strong>',
              buttons: [
                {
                  text: 'OK',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah);
                  },
                },
              ],
            });
        return alert.present();
      }else{
        if (new RegExp("^(http|https)://").test(_link)) {
          window.open(_link, '_blank', 'location=no');
        } else {
          router.push(_link);
        }
      }
    },

    checkRedirect(link) {
      if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
        link.redirect = '/app/dashboard/' + link.id;
      } else {
        link.redirect = link.target.split('#')[1]
      }
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    determineTarget(link) {

      return link.redirect;
    },
    async prepareLinks(id) {
      this.parentLink = (await this.getLinkById(id));
      this.menulinks = (await this.getChildren(id)).sort((a, b) => a.order - b.order);
      this.menulinks.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    }
  },
  async created() {
    let id = this.$route.params.id;
    this.prepareLinks(id);

  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        let id = this.$route.params.id;
        this.prepareLinks(id)
      }
    },
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'DashboardSub') {
          let id = route.params.id;
          this.prepareLinks(id)
        }
      }
    }
  }
};
</script>
<style lang="scss">


</style>
